<!-- 文件中文名: 经销商注册未登录 -->
<!-- http://localhost:8100/#/memberRegister-noLogin?recommendNo=RUM11111111&linkNo=RUM11111111&lr=1 -->
<template>
  <div class="MemberRegisterNoLogin app-container" style="text-align: left">
    <div v-loading="doLoading">
      <el-form
          ref="registerRef"
          :model="registerInfo"
          :rules="rules"
          label-width="auto"
          :label-position="windowWidth"
          size="mini"
          style="width: 80%;margin: 0 auto"
      >
        <!-- 基本信息 -->
        <h3 style="color: #b3640d">{{ $t('Common.basicInfomation') }}</h3>
        <!-- 选择分公司 -->
        <el-form-item :label="$t('required.select.company')" required>
          <el-select v-model="registerInfo.companyCode" style="width: 80%" :placeholder="$t('mi.input.select')" @change="setCompanyCode">
            <el-option v-for="i in companycodeList" :key="i.code" :label="i.name" :value="i.code"/>
          </el-select>
        </el-form-item>
        <!-- 推荐人编号 -->
        <el-form-item :label="$t('mi.sellerNo') + ':'" prop="recommendNo">
          <el-input ref="recommendNoInput" v-model.trim="registerInfo.recommendNo" clearable style="width: 80%"
                    @blur="recommendNoBlur($event.target.value)"/>
          <span :class="{ account_name_css: recommendName_span_show }" class="red-span">{{ recommendName_span }}</span>
        </el-form-item>
        <!-- 安置人编号 -->
        <el-form-item :label="$t('mi.servicerNo') + ':'" prop="linkNo">
          <el-input ref="linkNoInput" v-model.trim="registerInfo.linkNo" clearable style="width: 80%"
                    @blur="tmpLinkNoBlur($event.target.value)"/>
          <span :class="{ account_name_css: tmpLinkNo_span_show }" class="red-span">{{ tmpLinkNo_span }}</span>
        </el-form-item>
        <!-- 位置 -->
        <el-form-item :label="$t('sys.Location')" required>
          <el-radio-group v-model="registerInfo.lr">
            <el-radio :disabled="disabled1" label="0" @change="changRadio($event)">{{ $t('common.left') }}</el-radio>
            <el-radio :disabled="disabled2" label="1" @change="changRadio($event)">{{ $t('common.right') }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- 代办处编号 -->
        <el-form-item :label="$t('miAgent.agentNo') + ':'" prop="agentNo">
          <el-select v-model="registerInfo.agentNo" style="width: 80%"
                     :placeholder="$t('Please_select_first') + $t('sys.company.code')" :disabled="agentNoDisabled"
                     @change="agentNoChange">
            <el-option v-for="i in agentNoList" :key="i.value" :label="i.value" :value="i.value">
              <span style="float: left">{{ i.lable }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ i.value }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 编号方式 -->
        <el-form-item :label="$t('Number_way')+':'" required>
          <!-- 自定义后三位 -->
          <el-radio v-model="memberNoType" label="1">{{ $t('Custom.after_three') }}</el-radio>
          <!-- 自定义全部 -->
<!--          <el-radio v-model="memberNoType" label="2">{{ $t('Custom.whole') }}</el-radio>-->
        </el-form-item>
        <!-- 会员编号 -->
        <el-form-item v-if="memberNoType==='1'" :label="$t('poOrder.memberNo')+':'" required>
          <el-input v-model="threeMemberNo" maxlength="3" style="width: 80%"
                    :placeholder="$t('Please_enter_three_Letters_or_numbers')" @blur="getMemberno">
            <template slot="prepend">{{ memeberRegular || '' }}</template>
          </el-input>
          <span v-if="this.canregister=== false"
                style="color: red;font-weight: 700;font-size: 18px">{{ this.prohibitregistration }}</span>
          <span v-if="this.canregister=== true"
                style="color: green;font-weight: 700;font-size: 18px">{{ this.prohibitregistration }}</span>
        </el-form-item>
        <!-- 会员编号 -->
        <el-form-item  v-if="memberNoType==='2'" :label="$t('poOrder.memberNo')+':'" required>
          <el-input v-model="registerInfo.memberNo" style="width: 80%"
                    placeholder=" " @blur="checkMemberNo" />
          <span v-if="this.canregister=== false"
                style="color: red;font-weight: 700;font-size: 18px">{{ this.prohibitregistration }}</span>
          <span v-if="this.canregister=== true"
                style="color: green;font-weight: 700;font-size: 18px">{{ this.prohibitregistration }}</span>
        </el-form-item>
        <!-- 姓名 -->
        <el-form-item :label="$t('csServiceCenter.trueName')" required>
          <el-input v-model="registerInfo.name" :placeholder="$t('bvPointChange.userName')" style="width: 80%"
                    @blur="setRecName"/>
        </el-form-item>
        <!-- 电子邮件 -->
        <el-form-item :label="$t('sys.email')" prop="email" required>
          <el-input v-model="registerInfo.email" :placeholder="$t('sys.email')" style="width: 80%"/>
          <p style="color: red" v-if="!registerInfo.email">{{this.$t('Please.fill.in.the.email')}}</p>
        </el-form-item>
        <!-- 电话 -->
        <el-form-item :label="$t('alCompany.phone')" required>
          <el-input v-model="registerInfo.mobile" :placeholder="$t('alCompany.phone')" style="width: 80%"/>
        </el-form-item>
        <!-- 身份证号 -->
        <el-form-item :label="$t('bdSendRecordToBankReport.memberIdCH')" :required="registerInfo.companyCode==='TRA'">
          <el-input v-model="registerInfo.paperNo" :placeholder="$t('bdSendRecordToBankReport.memberIdCH')"
                    style="width: 80%"/>
        </el-form-item>
        <!-- 收货信息 -->
        <h3 style="color: #b3640d">{{ $t('register.us.legend.shipping') }}</h3>
        <!--          地址-->
        <el-form-item :label="$t('mi.address.id')" required size="mini">
          <el-cascader v-model="regionCodeList" :props="props" @change="handleChange" filterable style="width: 80%"/>
        </el-form-item>
        <!--          详细地址-->
        <el-form-item :label="$t('miAgent.storeAddr')" required size="mini">
          <el-input v-model="registerInfo.recAddr" style="width: 80%"/>
        </el-form-item>
        <!--        收货人姓名-->
        <el-form-item :label="$t('busi.JPorder.receiverName')+(':')" required>
          <el-input v-model="registerInfo.recName" readonly :placeholder="$t('info.input.receiverName')" onfocus="this.removeAttribute('readonly')"
                    style="width: 80%"/>
        </el-form-item>
        <!-- 密码设置 -->
        <h3 style="color: #b3640d">{{ $t('miAgent.title.LoginInfo') }}</h3>
        <!-- 设置密码 -->
        <el-form-item :label="$t('sysUser.password') + ':'" prop="password">
          <el-input v-model.trim="registerInfo.password" clearable show-password style="width: 80%"/>
        </el-form-item>
        <!-- 重复密码 -->
        <el-form-item :label="$t('sysUser.repeatPassword') + ':'" prop="tmpRePassword">
          <el-input v-model.trim="registerInfo.tmpRePassword" clearable show-password style="width: 80%"/>
        </el-form-item>
      </el-form>
      <div style="text-align: center; margin-bottom: 10px">
        <!--保存-->
        <el-button v-loading="doLoading" size="mini" type="success" @click="btnRegisterInfoSave">
          {{ $t('button.save') }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
  import {getMemberNoDateFormat, memberNoIsExit, merRestFist} from "@/api/register/register"
  import {miMemberInfo2} from "@/api/userInfo/userInfo"
  import {agentListByCompanyCode, miMemberRightNum} from "@/api/common/common"
  import {getCompanyList, commonGetRecAddrs} from "@/api/rus/api"

  export default {
    name: 'MemberRegisterNoLogin',
    data() {
      return {
        memberNoType: '1',
        memberNoSix: '',
        threeMemberNo: '',
        memeberRegular: '',
        agentNoDisabled: false,
        agentNoList: [],
        tableLoading: false,
        prohibitregistration: '',
        canregister: false,
        props: {
          lazy: true,
          lazyLoad(node, resolve) {
            const {level} = node
            if (level === 0) {
              commonGetRecAddrs().then(res => {
                const nodes = res.data.map(item => ({
                  value: item.country,
                  label: `${item.country}`,
                }))
                resolve(nodes)
              }).catch(err => {
                console.warn(err)
              })
            } else {
              commonGetRecAddrs({country: node.value}).then(res => {
                const nodes = res.data.map(item => ({
                  value: item.id,
                  label: `${item.province}`,
                  leaf: true
                }))
                resolve(nodes)
              }).catch(err => {
                console.warn(err)
              })
            }
          }
        },
        regionCodeList: [],
        companycodeList: [], // 公司列表
        registerInfo: {
          recommendNo: null,
          linkNo: null,
          lr: null,
          totalFc: 0,
          payAmount: 0,
          recAddr: '',
          recName: '',
          name: '',
          petName: '',
          agentNo: ''
        },
        doLoading: false,
        recommendName_span_show: false,
        tmpLinkNo_span_show: false,
        recommendName_span: '',
        tmpLinkNo_span: '',
        tmpLinkNo_num_span: '',
        memberNo_span: '',
        disabled1: true,
        disabled2: true,
        rules: {
          agentNo: [{required: true, message: this.$t('label.pleaseFillIn'), trigger: '[blur, change]'}],
          email: [{required: true, message: this.$t('label.pleaseFillIn'), trigger: 'blur'}],
          recommendNo: [{required: true, message: this.$t('label.pleaseFillIn'), trigger: '[blur, change]'}],
          linkNo: [{required: true, message: this.$t('label.pleaseFillIn'), trigger: '[blur, change]'}],
          name: [{required: true, message: this.$t('label.pleaseFillIn'), trigger: '[blur, change]'}],
          paperType: [{required: true, message: this.$t('label.pleaseFillIn'), trigger: '[blur, change]'}],
          paperNo: [{required: true, message: this.$t('label.pleaseFillIn'), trigger: '[blur, change]'}],
          country: [{required: true, message: this.$t('label.pleaseFillIn'), trigger: '[blur, change]'}],
          storeAddr: [{required: true, message: this.$t('label.pleaseFillIn'), trigger: '[blur, change]'}],
          mobile: [{required: true, message: this.$t('label.pleaseFillIn'), trigger: '[blur, change]'}],
          accountBank: [{required: true, message: this.$t('label.pleaseFillIn'), trigger: '[blur, change]'}],
          accountName: [{required: true, message: this.$t('label.pleaseFillIn'), trigger: '[blur, change]'}],
          accountCode: [{required: true, message: this.$t('label.pleaseFillIn'), trigger: '[blur, change]'}],
          password: [{required: true, message: this.$t('label.pleaseFillIn'), trigger: '[blur, change]'}],
          tmpRePassword: [{required: true, message: this.$t('label.pleaseFillIn'), trigger: '[blur, change]'}],
        },
        total: 0,
        initDict: {
          outCompanyList: 'out.company.list'
        },
      }
    },
    computed: {
      outCompanyList() {
        return this.$ll(this.initDict.outCompanyList)
      },
      windowWidth() {
        let windowWidth = document.documentElement.clientWidth || document.body.clientWidth
        return windowWidth < 991 ? 'top' : 'left'
      }
    },
    async created() {
      let MemberRegisterData = sessionStorage.getItem("MemberRegisterData")
      if (MemberRegisterData) {
        this.$message.error('There.New.Registered.Dealer')
        await this.$router.push(`/goodslist`)
      }
      this.doLoading = true
      await getCompanyList({'area': sessionStorage.getItem("AreaCode")}).then((res) => {
        this.companycodeList = res.data.filter(item => item.code !== 'WRU' && item.code !== 'WXX')
        this.doLoading = false
      }).catch((err) => {
        console.warn(err)
        this.doLoading = false
      })
    },
    mounted() {
      const getInfo = this.$route.query
      if (getInfo.recommendNo) {
        this.registerInfo.recommendNo = getInfo.recommendNo
        this.$refs.recommendNoInput.focus()
        setTimeout(() => {
          this.$refs.recommendNoInput.blur()
        }, 500)
      }
      if (getInfo.linkNo) {
        this.registerInfo.linkNo = getInfo.linkNo
        this.$refs.linkNoInput.focus()
        setTimeout(() => {
          this.$refs.linkNoInput.blur()
        }, 500)
      }
      if (getInfo.lr) {
        this.registerInfo.lr = getInfo.lr
      }
      this.getMemberNoTime()
    },
    methods: {
      agentNoChange(val) {
        this.memeberRegular = val + this.memberNoSix
      },
      getMemberNoTime() {
        getMemberNoDateFormat().then(res => {
          this.memberNoSix = res.data
        })
      },
      getMemberno() {
        if (this.threeMemberNo && this.threeMemberNo.length === 3) {
          if (this.threeMemberNo === '000') {
            this.threeMemberNo = ''
            this.prohibitregistration = ' '
            this.registerInfo.memberNo = ''
            return this.$message.info(this.$t('memberNo_no_000')) // 会员编号后三位不能为000
          }
          let regex = /^[\w =+_-]+$/ // 只允许输入数字,大小写字母,-_+=
          if (regex.test(this.threeMemberNo)) {
            memberNoIsExit({'memberNo': this.memeberRegular + this.threeMemberNo}).then(res => {
              if (res.data !== null) {
                this.prohibitregistration = 'X'
                this.canregister = false
                this.$message.error(res.data)
              } else {
                this.prohibitregistration = '✓'
                this.canregister = true
                this.registerInfo.memberNo = this.memeberRegular + this.threeMemberNo
              }
            }).catch(err => {
              console.error(err)
            })
          } else {
            this.threeMemberNo = ''
            this.prohibitregistration = ' '
            this.registerInfo.memberNo = ''
          }
        } else {
          this.prohibitregistration = ' '
          this.registerInfo.memberNo = ''
        }
      },
      checkMemberNo() {
        let regex = /^[\w =+_-]+$/ // 只允许输入数字,大小写字母,-_+=
        if (!regex.test(this.registerInfo.memberNo)) {
          this.registerInfo.memberNo = ''
          this.prohibitregistration = 'X'
          this.canregister = false
          return false
        }
        memberNoIsExit({'memberNo': this.registerInfo.memberNo}).then(res => {
          if (res.data !== null) {
            this.prohibitregistration = 'X'
            this.canregister = false
            this.$message.error(res.data)
          } else {
            this.prohibitregistration = '✓'
            this.canregister = true
          }
        }).catch(err => {
          console.error(err)
        })
      },
      handleChange(val) {
        this.registerInfo.regionCode = val[1]
      },
      formCheck() {
        if (this.registerInfo.companyCode==='TRA'&&!this.registerInfo.paperNo) {
          this.$message.error(this.$t('me.inputcardnumber')) // 请输入证件号码
          return false
        }
        if (this.memberNoType === '1') {
          if (!this.threeMemberNo) {
            return this.$message.error(this.$t('memberNo.required')) // 请输入会员编号
          }
          if (this.threeMemberNo === '000') {
            return this.$message.error(this.$t('memberNo_no_000')) // 会员编号后三位不能为000
          }
          if (this.threeMemberNo && this.threeMemberNo.length !== 3) {
            return this.$message.error(this.$t('Please_correct_memberNo_three')) // 请输入正确的后三位会员编号
          }
        } else if (this.memberNoType === '2') {
          if (!this.registerInfo.memberNo) {
            return this.$message.error(this.$t('memberNo.required')) // 请输入会员编号
          }
        }
        if (!this.registerInfo.recAddr) {
          this.$message.error(this.$t('Please.Selec.da').toString())
          return false
        }
        if (!this.registerInfo.recName) {
          this.$message.error(this.$t('info.rec.name.required').toString())
          return false
        }
        return true
      },
      setRecName() {
        this.registerInfo.recName = this.registerInfo.name.toString()
      },
      setRecInfo(val) {
        this.registerInfo.recAddr = val.storeAddr
        this.registerInfo.recName = val.name
        this.registerInfo.recPhone = val.mobile
        this.registerInfo.recNo = val.agentNo
      },
      changeStatus(val) {
        this.registerInfo.totalFc = val
      },
      changRadio(val) {
        this.registerInfo.lr = val
      },
      btnRegisterInfoSave() {
        if (!this.formCheck()) {
          return false
        }
        if (this.registerInfo.password !== this.registerInfo.tmpRePassword) {
          return this.$message.error(this.$t('error.password.not.accord').toString()) // 两次密码不一致
        }

        this.registerInfo.memberNo = this.memberNoType==='1' ? this.memeberRegular + this.threeMemberNo : this.registerInfo.memberNo
        this.registerInfo.memberNo = this.registerInfo.memberNo.trim()

        this.doLoading = true
        this.$refs['registerRef'].validate((valid) => {
          if (valid) {
            merRestFist(this.registerInfo)
             .then((res) => {
               if (res.success) {
                 this.$router.push({path: "/member-register-no-login-res", query: {resNo: res.data}})
               }
               this.doLoading = false
             })
             .catch((err) => {
               console.log(err)
               this.doLoading = false
             })
          } else {
            this.doLoading = false
          }
        })
      },
      setCompanyCode() {
        this.memeberRegular = ''
        this.registerInfo.agentNo = ''
        agentListByCompanyCode({'compayCode': this.registerInfo.companyCode}).then(res => {
          this.agentNoList = res.data
        })
      },
      recommendNoBlur(val) {
        if (val) {
          miMemberInfo2({code: val})
           .then((res) => {
             this.recommendName_span = res.data.name
             this.recommendName_span_show = false
             // if (res.data.companyCode == this.registerInfo.companyCode) {
             //   this.registerInfo.agentNo = res.data.agentNo
             //   this.memeberRegular = res.data.agentNo + this.memberNoSix
             //   this.agentNoDisabled = true
             // } else {
             //   this.agentNoDisabled = false
             //   agentListByCompanyCode({'compayCode': this.registerInfo.companyCode}).then(res => {
             //     this.registerInfo.agentNo = ''
             //     this.memeberRegular = ''
             //     this.agentNoList = res.data
             //   })
             // }
           })
           .catch(() => {
             this.recommendName_span = ''
             this.recommendName_span_show = true
           })
        } else {
          this.recommendName_span = ''
          this.recommendName_span_show = false
        }
      },
      tmpLinkNoBlur(val) {
        this.registerInfo.lr = ''
        if (val) {
          miMemberRightNum({memberNo: val})
           .then((res) => {
             if (res.data.leftMem == null || res.data.leftMem == '') {
               this.disabled1 = false
             }
             if (res.data.rightMem == null || res.data.rightMem == '') {
               this.disabled2 = false
             }
             this.tmpLinkNo_span = res.data.name
             this.tmpLinkNo_num_span = res.data.num
           })
           .catch(() => {
             this.tmpLinkNo_span = ''
             this.tmpLinkNo_num_span = ''
             this.tmpLinkNo_span_show = true
           })
        } else {
          this.tmpLinkNo_span = ''
          this.tmpLinkNo_num_span = ''
          this.tmpLinkNo_span_show = false
        }
      },
    }
  }

</script>

<style lang="scss" scoped>
  .account_name_css:after {
    content: 'X';
    color: #ff4949;
  }

  .red-span {
    color: red;
  }

  .register_3_css {
    display: flex;

    ::v-deep .el-form-item {
      margin-bottom: 0
    }
  }
</style>
